import L from "leaflet";
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import {OfflineEvent} from "../interfaces";
import React from "react";
import useStore, {prepareUrlState} from "../State";
import {Search} from "../search";
import useUrlState from "@ahooksjs/use-url-state";
import blue_marker from "../static/img/blue_marker.png"
import green_marker from "../static/img/green_marker.png"

const blueMarker = new L.Icon({
    iconUrl: blue_marker,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
})

const greenMarker = new L.Icon({
    iconUrl: green_marker,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
})

const EventMarkers = () => {
    const { setList, setIsLoading } = useStore((state: any) => ({ setList: state.setList, setIsLoading: state.setIsLoading }));
    const [state, setState] = useUrlState();
    const map_change_handler = (map: any, extra: string = "") => {
        const new_state = prepareUrlState(state, {
            LatLngBounds: map.getBounds(),
        });
        setState(new_state)
    }
    const map = useMapEvents({
        zoomend: () => {
            map_change_handler(map, "zoomend")
        },
        moveend: () => {
            map_change_handler(map, "moveend")
        }
    });
    return null;
}

export const Map = () => {
    let {center, offline_events, selected_event} = useStore((state) => ({center: state.center, offline_events: state.offline_list, selected_event: state.selected_event}));
    // console.log(offline_events);
    const [state, _] = useUrlState(); // eslint-disable-line
    let bounds: any = undefined;
    let mapCenter: any = center;
    if(state.ne_lat && state.ne_lng && state.sw_lat && state.sw_lng){
        bounds = new L.LatLngBounds(
            [state.sw_lat, state.sw_lng],
            [state.ne_lat, state.ne_lng],
        )
        mapCenter = undefined;
    }

    return (
        <MapContainer bounds={bounds} boundsOptions={{padding: [0, 0]}} center={mapCenter} minZoom={3} zoom={3} style={{height: '100%'}}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            {offline_events.map((evt: OfflineEvent) => {
                return (<Marker
                    icon={evt.id === selected_event?greenMarker:blueMarker}
                    position={[evt.address.location.latitude, evt.address.location.longitude]}
                    key={evt.id}
                    // eventHandlers={{
                    //     mouseover: (event) => console.log(event),
                    //     mouseout: (event) => console.log(event)
                    //   }}
                >
                    <Popup key={evt.id}>
                        {evt.name}
                    </Popup>
                </Marker>)
            })}
            <EventMarkers/>
        </MapContainer>
    )
}
