
export const changeDelay = (timer: number, setTimer: any) => {
    return (change:any) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(0);
        }
        setTimer(
            setTimeout(() => {
                console.log(change);
                console.log(change.target);
            }, 500) as unknown as number);
    }
}

export const format_date = (date_obj: Date) => {
    let month_padding = "", day_padding = "";
    if (date_obj.getMonth()+1 < 10){
        month_padding = "0"
    }
    if (date_obj.getDate() < 10){
        day_padding = "0"
    }
    return  day_padding + date_obj.getDate() + "-" + month_padding + (date_obj.getMonth()+1) + "-" + date_obj.getFullYear()
}

export const parse_date = (date_str: string) => {
    const [day, month, year] = date_str.split("-")
    return new Date(+year, +month - 1, +day)
}
