import {create} from "zustand"
import {Event, OfflineEvent} from "./interfaces";
import {format_date} from "./utils";

const get_offline_events = (events: Event[]) => {
  return  events.filter((event) => event.address !== undefined && event.address != null) as OfflineEvent[];
}

const get_center_location = (events: Event[]) => {
  let offline_events = get_offline_events(events);
  const {length} = offline_events;
  return offline_events.reduce((acc: Array<number>, val: OfflineEvent) => {
      return [
          acc[0] + val.address.location.latitude/length,
          acc[1] + val.address.location.longitude/length
      ]
  }, [0, 0]);
}

const useStore = create((set : any) => ({
    list: [] as any,
    offline_list: [] as any,
    center: [0, 0],
    selected_event: 0,
    locations: [],
    isLoading: false,
    setIsLoading: (loading_state: boolean) => {
        console.log("Set isLoading", loading_state)
        set({isLoading: loading_state})
    },
    setSelectedEvent: (new_event: number) => set({selected_event: new_event}),
    deselectEvent: () => set({selected_event: 0}),
    setList: (newListData: any) => set((state: any) => {
        let newList = newListData.results
        let offline_events = get_offline_events(newList)
        return {
            list: newList,
            offline_list: offline_events,
            center: get_center_location(offline_events),
            // locations: newListData.locations
        }
    }),
}))

export function prepareUrlState(oldState: any, newState: any){
    const newCriteria = {...newState}

    if (newCriteria.date_range !== undefined || newCriteria.date_range === null){
        if(newCriteria.date_range && newCriteria.date_range.length === 2) {
            newCriteria.date_after = format_date(newCriteria.date_range[0])
            newCriteria.date_before = format_date(newCriteria.date_range[1])
        } else if (newCriteria.date_range === null){
            newCriteria.date_after = undefined
            newCriteria.date_before = undefined
        }
        delete newCriteria.date_range

    }

    if (newCriteria.LatLngBounds){
        const llb = newCriteria.LatLngBounds;
        newCriteria.sw_lng= llb._southWest.lng.toFixed(3);
        newCriteria.sw_lat= llb._southWest.lat.toFixed(3);
        newCriteria.ne_lng= llb._northEast.lng.toFixed(3);
        newCriteria.ne_lat= llb._northEast.lat.toFixed(3);
        delete newCriteria.LatLngBounds;
    }

    // if (Array.isArray(newCriteria.locations)) {
    //     if (newCriteria.locations.length > 0 && newCriteria.locations[0] !== null && typeof newCriteria.locations[0] === 'object') {
    //         newCriteria.locations = newCriteria.locations.map((obj: Record<string, any>) => obj.value);
    //     }
    // }

    const finalState = {
        ...oldState,
        ...newCriteria,
    }

    for (const key in finalState as any) {
        if (finalState[key] === '' || finalState[key] === null) {
            finalState[key] = undefined
        }
        if (key==='cfp_open' && finalState[key] === false){
            finalState[key] = undefined
        }
    }

    return finalState
}

export {useStore};
export default useStore;
