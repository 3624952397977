import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Select,
    Text
} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {Field, Form, Formik, useFormik, useFormikContext} from "formik";
import axios from "axios";
import useStore, {prepareUrlState} from "../State";
import useUrlState from "@ahooksjs/use-url-state";
import {parse_date} from "../utils";
import {useEffect} from "react";
import {Search} from "../search";
import AutoSave from "./AutoSave";

const loadOptions = async (
        inputValue: string,
    ) => {
        let response = await axios.get("/api/location-autocomplete?q=" + inputValue);
        return response.data;
    };


const FormObserver = () => {
    const {values} = useFormikContext() as any;
    const [state, setState] = useUrlState();
    const {setList} = useStore((state: any) => ({
        setList: state.setList,
    }));

    useEffect(() => {
        const new_state = prepareUrlState(state, values);
        setState(new_state)
        console.log("Trigger search from FormObserver", values, state)
        Search({...new_state, setList});
    }, [values, setList, setState, state]);
    return null;
};
export const SearchBox = () => {
    const [state, setState] = useUrlState(); // eslint-disable-line
    let date_range: (Date[] | undefined);
    if (state.date_after && state.date_before) {
        date_range = [parse_date(state.date_after), parse_date(state.date_before)]
    } else {
        date_range = undefined
    }

    const initialValues = {
        query: state.query,
        location_type: state.location_type,
        cfp_open: state.cfp_open,
        date_range: date_range,
        // locations: locations
    };

    const handleFormChange = (values: any, { setSubmitting }: any) => {
        // Update URL state when form values change
        console.log("Handle form change", values)
        setState({ ...values });
      };

    return (
        <Formik initialValues={initialValues} onSubmit={handleFormChange}>
            {({values, setFieldValue, handleChange, handleSubmit}) => {
                return (<Form onChange={() => handleSubmit()}>
                    <FormControl>
                        <Flex alignItems={'left'} minWidth='max-content'>
                            <HStack spacing={8}>
                                <InputGroup>
                                    <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300'/>}
                            />
                            <Input id='search' placeholder='Search...' htmlSize={10}
                                   width='auto' name='query'
                                   value={values.query}/>
                                </InputGroup>
                                <Box>
                                    <Select color={'gray.400'} placeholder="Location type" minW={150}
                                            onChange={handleChange}
                                            id="location_type"
                                            value={values.location_type}>
                                        {["online", "offline", "hybrid"].map((i: string) => (
                                            <option key={i}
                                                    value={i}>{i[0].toUpperCase() + i.slice(1).toLowerCase()}</option>))}
                                    </Select>
                                </Box>
                                <Box>
                                    <Checkbox name="cfp_open" size={'md'} minWidth='max-content' onChange={handleChange}
                                              isChecked={values.cfp_open == 'true'}><Text color={'gray.500'}>CFP Open</Text></Checkbox>
                                </Box>
                                {/*<Box minWidth={250} maxWidth={500} maxH={100} zIndex={2}>*/}
                                {/*    <AsyncSelect name="locations" onChange={(e: any) => setFieldValue('locations', e)} defaultValue={values.locations} loadOptions={loadOptions} isMulti isClearable defaultOptions cacheOptions/>*/}
                                {/*</Box>*/}
                                <Box minWidth='max-content' pr={'40px'}>
                                    <DateRangePicker format={"dd/MM/y"} minDate={new Date()} className={"chakra-input"} onChange={(value: any) => {
                                        setFieldValue('date_range', value);
                                    }} value={values.date_range}/>
                                </Box>
                            </HStack>
                        </Flex>
                    </FormControl>
                    {/*<FormObserver />*/}
                </Form>)
            }}
        </Formik>
    );
};