import {Box, SimpleGrid, VStack} from "@chakra-ui/react";
import React from "react";
import {Map} from "./Map";
import useStore from "../State";
import {ResultEntry} from "./ResultEntry";
import {Event} from "../interfaces";


export const Results = () => {
    const [events, selected_event, setSelectedEvent, deselectEvent] = useStore((state) => [state.list, state.selected_event, state.setSelectedEvent, state.deselectEvent])
    return (
        <SimpleGrid columns={2} spacing={10} maxH="80vh" px="4">
            <Box h="80vh" maxH="80vh" overflow="auto">
                <VStack>
                    {events.map((evt: Event) => {
                        return ResultEntry(evt, selected_event, setSelectedEvent, deselectEvent)
                    })}
                </VStack>
            </Box>
            <Box h="full">
                <Map />
            </Box>
        </SimpleGrid>
    )
}