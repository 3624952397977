import {Box, Code, Image, Link, Stack, Text} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import React from 'react'
import {Event} from "../interfaces";

export const ResultEntry = (evt: Event, selected_event: number, setSelectedEvent: Function, deselectEvent: Function) => {
    let img_src = "https://picsum.photos/64?random=" + evt.id
    // const [setSelectedEvent, deselectEvent] = useStore((state) => [state.setSelectedEvent, state.deselectEvent]);
    // onMouseEnter={() => {setSelectedEvent(evt.id)}}
    // const selected_event = useStore((state) => state.selected_event)
    return (
        <Stack direction="row" key={evt.id} w="100%" border={'2px'} borderColor={selected_event === evt.id?'green.200':'gray.200'} p="2" borderRadius="md" onMouseEnter={() => {setSelectedEvent(evt.id)}} onMouseLeave={() => deselectEvent()} >
            <Image src={img_src} />
            {/*<Spacer />*/}
            <Box>
            <Text fontWeight="medium">
            {evt.name}
            </Text>
                <Text>
                    {evt.date_after} - {evt.date_before}
                </Text>
            <Text>
            {evt.address ? evt.address.city.name_std + ", " + evt.address.city.country_std : ""}
            </Text>

                <Stack direction="row">
            {evt.tags.map((tag: any) => {
                return <Code key={tag.name}>{tag.name}</Code>
            })}
                    </Stack>



                </Box>
            <Box>
                {evt.homepage? <Link href={evt.homepage} isExternal>
  {evt.homepage} <ExternalLinkIcon mx='2px' />
</Link>: ""}
</Box>
        </Stack>
    )
}