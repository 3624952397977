import {SearchParams} from "./interfaces";
import {AxiosResponse, default as axios} from "axios";
import qs from 'qs';

export function Search(
    {
        cfp_open,
        location_type,
        locations,
        query,
        setList,
        sw_lng,
        sw_lat,
        ne_lng,
        ne_lat,
        date_after,
        date_before,
    }: SearchParams): Promise<any> {
    let searchParams: any = {
        ...(cfp_open ? {cfp_open} : null),
        ...(location_type ? {location_type} : null),
        ...(locations ? {locations} : null),
        ...(query ? {query} : null),
        ...(sw_lng ? {sw_lng} : null),
        ...(sw_lat ? {sw_lat} : null),
        ...(ne_lng ? {ne_lng} : null),
        ...(ne_lat ? {ne_lat} : null),
        ...(date_after ? {date_after} : null),
        ...(date_before ? {date_before} : null)
    }
    console.log("Searching...", searchParams)
    return axios.get("https://api.confpedia.com/api/events/search/", {
        params: searchParams,
        paramsSerializer: {
            serialize: (params: any) => {
                return qs.stringify(params, {indices: false})
            }
        }
    })
        .then((response: AxiosResponse) => {
            setList(response.data);
        })
        .catch((err: any) => {
            console.log(err);
        })
}