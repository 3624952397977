import * as React from "react"
import {
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react"

import Header from "./components/Header";
import {Results} from "./components/Results";
import {useEffect, useState} from "react";
import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";

import useStore from "./State";
import useUrlState from "@ahooksjs/use-url-state";
import {Search} from "./search";
import {Footer} from "./components/Footer";


const theme = extendTheme({
  styles: {
    global: {
      '.leaflet-pane': {
        // zIndex: 'auto'
      },
      '.react-daterange-picker__calendar': {
        zIndex: '500'
      }
    },
  }
})


const App = () => {
  const { setList } = useStore((state: any) => ({ setList: state.setList }));
  const [state, _] = useUrlState(); // eslint-disable-line
  useEffect(() => {
    console.log("Trigger search from App", state)
    Search({...state, setList})
  }, [state, setList]);

  return (
      <ChakraProvider theme={theme}>
    <Header />
      <Results />
        <Footer />
  </ChakraProvider>
  );

}

const app = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </BrowserRouter>
  );
};

export default app