import {
    Box,
    Flex,
    HStack,
    useColorModeValue,
    VStack, Image,
    Text,
} from '@chakra-ui/react';
import logo from "../static/img/logo.png";
import '../static/css/DatePicker.css'
import {SearchBox} from "./SearchBox";


export default function Header() {
    return (
        <VStack width="100%" spacing={0} alignItems={'left'}>
            <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} width="100%" mb={2}>
                <Flex h={16} justifyContent={'space-between'}>
                    <HStack spacing={8}>
                        <Box>
                            <Image src={logo} w="100px"/>
                        </Box>
                        <Text size="s">Find IT conference in seconds</Text>
                    </HStack>
                </Flex>
            </Box>

            <Box alignItems="left" pl={4}>
                <Flex mb={2} alignItems={'left'}>
                    <SearchBox></SearchBox>
                </Flex>
            </Box>
        </VStack>
    );
}